<template>
  <v-container>
    <v-row>
      <v-col class="mb-4">
        <h1 class="text-h6 mb-3 primary--text">¿APOSTAR POR EMPRESARIAS?</h1>

        <p>
          Si financias, por ejemplo, una televisión, te repagarán con el dinero que generan con su “otro” trabajo. Si financias
          emprendedoras te repagarán con el dinero que ganan en el negocio que están implementado.
        </p>
        <p>Es decir, <span class="secondary--text">estás consiguiendo que te paguen a la vez que están ingresando más.</span></p>
      </v-col>

      <!-- <v-col cols="12">
        <v-img :src="require('@/assets/images/bet_businesswomen.svg')" class="my-3" contain height="200" />
      </v-col> -->
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "BetOnBusinesswomen",
  created() {
    if (this.$vuetify.breakpoint.smAndDown) {
      this.$emit("setImageBg", {
        backgroundImage: "url(" + require("@/assets/images/backgrounds/bgmobile-betOnBusinesswomen.png") + ")"
      });
    } else {
      this.$emit("setImageBg", {
        backgroundImage: "url(" + require("@/assets/images/backgrounds/bg-betOnBusinesswomen.png") + ")"
      });
    }
  }
};
</script>
